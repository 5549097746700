import UserConsent from '@fundarealestate/user-consent';

UserConsent.init();
if (UserConsent.personalization()) {
    // optimizely
    const script = document.createElement('script');
    script.src = 'https://cdn.optimizely.com/js/18146640370.js';
    document.head.appendChild(script);

    // hotjar
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:2869769,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}
